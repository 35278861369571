<template>
  <div class="tw-container border border-1 border-dark p-lg-3 p-0">
    <!-- 標題 -->
    <p class="tw-border-start fw-bolder tw-text-size20 m-lg-0 mt-3 ms-3">
      結單/出貨/運送/付款
    </p>
    <hr />
    <!-- 上方區塊 -->
    <div class="row p-0">
      <!-- 左 -->
      <div class="col-lg-6 p-0">
        <!-- 付款與出貨方式說明 -->
        <div
          class="tw-container border border-1 border-dark mb-4"
          style="height: 240px"
        >
          <div class="mb-2">
            <span class="fw-bolder mb-2 me-2 p-1">付款與出貨方式說明</span>
            <button
              class="tw-btn tw-btn-success"
              @click="updateStoreInfo('updateDescription')"
            >
              更改
            </button>
          </div>
          <div class="form-floating">
            <textarea
              class="form-control"
              placeholder=""
              rows="3"
              id="PaymentAndShippingNote"
              style="height: 175px"
              maxlength="500"
              v-model="storeInfo.paymentDescription"
            ></textarea>
            <label for="PaymentAndShippingNote">輸入內容(500字)</label>
          </div>
        </div>
      </div>
      <!-- 右 -->
      <div class="col-lg-6 p-0">
        <!-- 出貨資訊 -->
        <div
          class="tw-container border border-1 border-dark mb-4"
          style="height: 240px; overflow-y: scroll"
        >
          <div class="mb-2">
            <span class="fw-bolder p-1 me-2">出貨資訊</span>
            <button
              class="tw-btn tw-btn-success"
              @click="updateStoreInfo('shippingInfo')"
            >
              儲存出貨資訊
            </button>
          </div>
          <!-- 寄件人姓名 -->
          <div class="mb-2" style="display: flex; align-items: center">
            <label style="min-width: 135px">寄件人姓名(15字)</label>
            <input
              type="text"
              class="form-control d-inline-block"
              placeholder="請輸入寄件人姓名"
              maxlength="15"
              v-model="storeInfo.shipmentName"
            />
          </div>
          <!-- 寄件人電話 -->
          <div class="mb-2" style="display: flex; align-items: center">
            <label style="min-width: 135px">寄件人電話(15字)</label>
            <input
              type="text"
              class="form-control d-inline-block"
              placeholder="請輸入寄件人電話"
              maxlength="15"
              v-model="storeInfo.shipmentPhoneNumber"
            />
          </div>
          <!-- 寄件人地址 -->
          <div class="mb-2" style="display: flex; align-items: center">
            <label style="min-width: 135px">寄件人地址(30字)</label>
            <input
              type="text"
              class="form-control d-inline-block"
              placeholder="請輸入寄件人地址"
              maxlength="30"
              v-model="storeInfo.shipmentAddress"
            />
          </div>
          <!-- 備註 -->
          <div class="mb-2 d-flex align-items-center">
            <label style="min-width: 100px">備註(300字)</label>
            <textarea
              class="form-control"
              rows="1"
              maxlength="300"
              placeholder="請輸入備註"
              v-model="storeInfo.shipmentNote"
            ></textarea>
          </div>
        </div>
      </div>
    </div>
    <!-- 中間區塊 -->
    <div class="row">
      <!-- 左 -->
      <div class="col-lg-6 p-0">
        <!-- 運送方式 -->
        <div
          class="tw-container border border-1 border-dark mb-4"
          style="height: 250px"
        >
          <span class="fw-bolder mb-2 me-2 p-1">運送方式</span>
          <button
            class="tw-btn tw-btn-success mb-2"
            @click="showModal('freight', 'add')"
          >
            新增運送方式
          </button>
          <AreaLoading v-if="storeShippingTypes.loading"></AreaLoading>
          <div
            class="table-responsive"
            style="height: 190px; overflow-y: scroll"
            v-else-if="storeShippingTypes.data.length > 0"
          >
            <table class="table table-hover">
              <thead class="float-header">
                <tr>
                  <th style="min-width: 80px">運送方式</th>
                  <th style="min-width: 80px">後臺可選</th>
                  <th style="min-width: 80px">顧客可選</th>
                  <th style="min-width: 85px">運費</th>
                  <th style="min-width: 100px">提示訊息</th>
                  <th style="min-width: 60px">操作</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="data in storeShippingTypes.data" :key="data.id">
                  <td>{{ data.name }}</td>
                  <td>
                    <p class="text-success" v-if="data.adminEnable">可選</p>
                    <p class="text-danger" v-else>不可選</p>
                  </td>
                  <td>
                    <p class="text-success" v-if="data.customerEnable">可選</p>
                    <p class="text-danger" v-else>不可選</p>
                  </td>
                  <td>NT${{ data.cost }}</td>
                  <td>{{ data.tipMessage }}</td>
                  <td>
                    <button
                      class="tw-btn tw-btn-success"
                      @click="showModal('freight', 'edit', data)"
                    >
                      編輯
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p class="text-danger fw-bolder" v-else>目前尚無任何運送方式!</p>
        </div>
      </div>
      <!-- 右 -->
      <div class="col-lg-6 p-0">
        <!-- 付款方式 -->
        <div
          class="tw-container border border-1 border-dark mb-4"
          style="height: 250px"
        >
          <span class="fw-bolder mb-2 me-2 p-1">付款方式</span>
          <button
            class="tw-btn tw-btn-success mb-2"
            @click="showModal('payfor', 'add')"
          >
            新增付款方式
          </button>
          <AreaLoading v-if="storePaymentTypes.loading"></AreaLoading>
          <div
            class="table-responsive"
            style="height: 190px; overflow-y: scroll"
            v-else-if="storePaymentTypes.data.length > 0"
          >
            <table class="table table-hover">
              <thead class="float-header">
                <tr>
                  <th style="min-width: 80px">付款方式</th>
                  <th style="min-width: 80px">後臺可選</th>
                  <th style="min-width: 80px">顧客可選</th>
                  <th style="min-width: 100px">付款須知</th>
                  <th style="min-width: 60px">操作</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="data in storePaymentTypes.data" :key="data.id">
                  <td>{{ data.name }}</td>
                  <td>
                    <p class="text-success" v-if="data.adminEnable">可選</p>
                    <p class="text-danger" v-else>不可選</p>
                  </td>
                  <td>
                    <p class="text-success" v-if="data.customerEnable">可選</p>
                    <p class="text-danger" v-else>不可選</p>
                  </td>
                  <td>{{ data.note }}</td>
                  <td>
                    <button
                      class="tw-btn tw-btn-success"
                      @click="showModal('payfor', 'edit', data)"
                    >
                      編輯
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p class="text-danger fw-bolder" v-else>目前尚無任何付款方式!</p>
        </div>
      </div>
    </div>
    <!-- 下方區塊 -->
    <div>
      <div class="row">
        <!-- 左 -->
        <div class="col-lg p-0">
          <!-- 配單模式 -->
          <div class="tw-container border border-1 border-dark mb-4">
            <p class="fw-bolder mb-2 p-1">配單模式</p>
            <p class="mb-2">免配單功能適用於「現貨充足」的社團</p>
            <!-- 選項 -->
            <div>
              <div class="form-check d-inline-block me-2 p-0">
                <input
                  class="form-check-input m-0 me-2"
                  type="radio"
                  value="true"
                  id="OrderMode1"
                  name="orderMode"
                  v-model="storeInfo.autoAllocated"
                />
                <label class="form-check-label" for="OrderMode1">
                  系統自動配單
                </label>
              </div>
              <div class="form-check d-inline-block p-0">
                <input
                  class="form-check-input m-0 me-2"
                  type="radio"
                  value="false"
                  id="OrderMode2"
                  name="orderMode"
                  v-model="storeInfo.autoAllocated"
                />
                <label class="form-check-label" for="OrderMode2">
                  賣家自行配單
                </label>
              </div>
            </div>
          </div>
          <!-- 顧客編輯結單數量 -->
          <div class="tw-container border border-1 border-dark mb-4">
            <p class="fw-bolder mb-2 p-1">顧客編輯結單數量</p>
            <!-- 選項 -->
            <div>
              <div
                class="form-check d-inline-block me-2 p-0"
                @click="
                  updateConfiguration('customerCanEditCheckoutQuantity', false)
                "
              >
                <input
                  class="form-check-input m-0 me-2"
                  type="radio"
                  :value="false"
                  id="CustomerEditQuantity1"
                  name="customerEditQuantity"
                  v-model="customerCanEditCheckoutQuantity"
                />
                <label class="form-check-label" for="CustomerEditQuantity1">
                  不允許(預設)
                </label>
              </div>
              <div
                class="form-check d-inline-block p-0"
                @click="
                  updateConfiguration('customerCanEditCheckoutQuantity', true)
                "
              >
                <input
                  class="form-check-input m-0 me-2"
                  type="radio"
                  :value="true"
                  id="CustomerEditQuantity2"
                  name="customerEditQuantity"
                  v-model="customerCanEditCheckoutQuantity"
                />
                <label class="form-check-label" for="CustomerEditQuantity2">
                  允許顧客自行修改
                </label>
              </div>
            </div>
          </div>
          <!-- 代客直寄選項 -->
          <div class="tw-container border border-1 border-dark mb-4">
            <p class="fw-bolder mb-2 p-1">代客直寄選項</p>
            <!-- 選項 -->
            <div>
              <div
                class="form-check d-inline-block me-2 p-0"
                @click="updateConfiguration('showDetail', false)"
              >
                <input
                  class="form-check-input m-0 me-2"
                  type="radio"
                  :value="false"
                  id="ValetDropshipping1"
                  name="valetDropshipping"
                  v-model="showDetail"
                />
                <label class="form-check-label" for="ValetDropshipping1">
                  不顯示
                </label>
              </div>
              <div
                class="form-check d-inline-block p-0"
                @click="updateConfiguration('showDetail', true)"
              >
                <input
                  class="form-check-input m-0 me-2"
                  type="radio"
                  :value="true"
                  id="ValetDropshipping2"
                  name="valetDropshipping"
                  v-model="showDetail"
                />
                <label class="form-check-label" for="ValetDropshipping2">
                  顯示選項、提醒出貨時不要附上價錢明細
                </label>
              </div>
            </div>
          </div>
          <!-- 顧客結單可填寫發票寄送地址 -->
          <div class="tw-container border border-1 border-dark mb-4">
            <p class="fw-bolder mb-2 p-1">顧客結單可填寫發票寄送地址</p>
            <!-- 選項 -->
            <div>
              <div
                class="form-check d-inline-block me-2 p-0"
                @click="
                  updateConfiguration(
                    'customerCheckoutCanSetReceiptInfo',
                    false
                  )
                "
              >
                <input
                  class="form-check-input m-0 me-2"
                  type="radio"
                  :value="false"
                  id="Bill1"
                  name="bill"
                  v-model="customerCheckoutCanSetReceiptInfo"
                />
                <label class="form-check-label" for="Bill1">不顯示 </label>
              </div>
              <div
                class="form-check d-inline-block p-0"
                @click="
                  updateConfiguration('customerCheckoutCanSetReceiptInfo', true)
                "
              >
                <input
                  class="form-check-input m-0 me-2"
                  type="radio"
                  :value="true"
                  id="Bill2"
                  name="bill"
                  v-model="customerCheckoutCanSetReceiptInfo"
                />
                <label class="form-check-label" for="Bill2">
                  顯示填寫發票資訊
                </label>
              </div>
            </div>
          </div>
          <!-- 開放顧客結單使用優惠 -->
          <!-- <div class="tw-container border border-1 border-dark mb-4">
            <p class="fw-bolder mb-2 p-1">開放顧客結單使用優惠</p>
            <div>
              <div class="form-check d-inline-block me-2 p-0">
                <input
                  class="form-check-input m-0 me-2"
                  type="radio"
                  value="false"
                  id="Promotions1"
                  name="promotions"
                  v-model="storeInfo.allowCustomerDiscount"
                />
                <label
                  class="form-check-label"
                  for="Promotions1"
                >
                  不允許(預設)
                </label>
              </div>
              <div class="form-check d-inline-block p-0">
                <input
                  class="form-check-input m-0 me-2"
                  type="radio"
                  value="true"
                  id="Promotions"
                  name="promotions2"
                  v-model="storeInfo.allowCustomerDiscount"
                />
                <label class="form-check-label" for="promotions2">
                  允許顯示促銷活動
                </label>
              </div>
            </div>
          </div> -->
        </div>
        <!-- 右 -->
        <div class="col-lg p-0"></div>
      </div>
    </div>
  </div>
  <!-- 新增/編輯運送方式 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="shippingTypeModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5
            class="modal-title tw-border-start fw-bolder"
            v-if="shippingTypeMethods === 'add'"
          >
            新增運送方式
          </h5>
          <h5 class="modal-title tw-border-start fw-bolder" v-else>
            編輯運送方式
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <!-- 運送方式名稱 -->
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              id="ShippingTypeName"
              placeholder="運送方式名稱"
              maxlength="10"
              v-model="shippingTypeData.name"
            />
            <label class="text-danger" for="ShippingTypeName"
              >*運送方式名稱(10字)</label
            >
          </div>
          <!-- 運費 -->
          <div class="form-floating mb-3">
            <input
              onwheel="this.blur()"
              type="number"
              min="0"
              class="form-control"
              id="shippingCost"
              placeholder="運費"
              v-model="shippingTypeData.cost"
              @input="
                shippingTypeData.cost = $methods.numberToFixed(
                  shippingTypeData.cost
                )
              "
            />
            <label for="shippingCost">運費</label>
          </div>
          <!-- 提示訊息 -->
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              id="ShippingTypeName"
              placeholder="提示訊息"
              maxlength="20"
              v-model="shippingTypeData.tipMessage"
            />
            <label for="ShippingTypeName">提示訊息(20字)</label>
          </div>
          <!-- 後臺可選 -->
          <div class="form-check form-switch p-0 d-inline-block me-4">
            <input
              class="form-check-input m-0 me-2"
              type="checkbox"
              role="switch"
              id="ShippingTypeAdminEnable"
              v-model="shippingTypeData.adminEnable"
            />
            <label class="form-check-label" for="ShippingTypeAdminEnable"
              >後臺可選</label
            >
          </div>
          <!-- 顧客可選 -->
          <div class="form-check form-switch p-0 d-inline-block">
            <input
              class="form-check-input m-0 me-2"
              type="checkbox"
              role="switch"
              id="ShippingTypeCustomerEnable"
              v-model="shippingTypeData.customerEnable"
            />
            <label class="form-check-label" for="ShippingTypeCustomerEnable"
              >顧客可選</label
            >
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="updateShippingType"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 新增/編輯付款方式 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="payforModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5
            class="modal-title tw-border-start fw-bolder"
            v-if="payforMethods === 'add'"
          >
            新增付款方式
          </h5>
          <h5 class="modal-title tw-border-start fw-bolder" v-else>
            編輯付款方式
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <!-- 付款方式名稱 -->
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              id="payforName"
              placeholder="請輸入付款方式名稱"
              maxlength="10"
              v-model="payforData.name"
            />
            <label class="text-danger" for="payforName"
              >*付款方式名稱(10字)</label
            >
          </div>
          <!-- 付款須知 -->
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              id="payforNote"
              placeholder="請輸入付款須知"
              maxlength="20"
              v-model="payforData.note"
            />
            <label for="payforNote">付款須知(20字)</label>
          </div>
          <!-- 後臺可選 -->
          <div class="form-check form-switch p-0 d-inline-block me-4">
            <input
              class="form-check-input m-0 me-2"
              type="checkbox"
              role="switch"
              id="payforAdminEnable"
              v-model="payforData.adminEnable"
            />
            <label class="form-check-label" for="payforAdminEnable"
              >後臺可選</label
            >
          </div>
          <!-- 顧客可選 -->
          <div class="form-check form-switch p-0 d-inline-block">
            <input
              class="form-check-input m-0 me-2"
              type="checkbox"
              role="switch"
              id="payforCustomerEnable"
              v-model="payforData.customerEnable"
            />
            <label class="form-check-label" for="payforCustomerEnable"
              >顧客可選</label
            >
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="updatePayfor"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 自動配單提醒訊息 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="autoAllocatedWarningModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">
            自動配單提醒訊息
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <p class="mb-2">是否確定選擇系統自動配單?</p>
          <p class="fw-bolder text-warning">
            提醒：系統自動配單後，顧客將無法在下單後刪除訂單
          </p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
            @click="storeInfo.autoAllocated = false"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="this.updateStoreInfo('autoAllocated')"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 空的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref=""
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">Title</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">...</div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button type="button" class="tw-btn tw-btn-success">確認</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Permission } from '@/methods/mixins/permission';

export default {
  data() {
    return {
       // modal
      shippingTypeModal: {},
      payforModal: {},
      autoAllocatedWarningModal: {},
       // data
      serverToken: '',
      storeId: 0,
      // 賣場資訊
      storeInfo: {
        paymentDescription: ''
      },
      // 運送方式資料
      storeShippingTypes: {
        loading: false,
        data: []
      },
      // 付款方式資料
      storePaymentTypes: {
        loading: false,
        data: []
      },
      // 運送方式操作方法
      shippingTypeMethods: 'add',
      // 新增/編輯，運送方式資料
      shippingTypeData: {
        name: '',
        adminEnable: false,
        customerEnable: false,
        cost: '',
        tipMessage: ''
      },
      // 付款方式操作方法
      payforMethods:'add',
      // 新增/編輯，付款方式資料
      payforData: {
        name: '',
        adminEnable: false,
        customerEnable: false,
        note: ''
      }
    }
  },
  mixins: [Permission],
  created() {
    this.initialization()
    this.getPrefer()
    this.getStoreInfo(['getShippingTypes','getPaymentTypes'])
  },
  mounted() {
    this.createModals([
      'shippingTypeModal','payforModal','autoAllocatedWarningModal'
    ])
  },
  watch: {
    // 配單模式
    'storeInfo.autoAllocated': {
      handler(val, oldVal) {
        if (oldVal!==undefined) {
          if (val === 'true') this.showModal('autoAllocated')
          else if (val === 'false') this.updateStoreInfo('autoAllocated')
        }
      }
    },
    // 開放顧客結單使用優惠
    'storeInfo.allowCustomerDiscount': {
      handler(val, oldVal) {
        if (oldVal!==undefined) this.updateStoreInfo('allowCustomerDiscount')
      }
    },
    // 個人賣場下單要求填寫收件地址
    'storeInfo.receivingInfoNeeded': {
      handler(val, oldVal) {
        if (oldVal!==undefined) this.updateStoreInfo('receivingInfoNeeded')
      }
    },
    // 電話號碼格式
    'storeInfo.shipmentPhoneNumber': {
      handler(val, oldVal) {
        if (val) {
          if ( !/^[0-9]*$/.test(val) ) {
            this.storeInfo.shipmentPhoneNumber = val.replace(/[^\d]/g, "");
          }
        } 
      }
    },
  },
  computed: {
    // 偏好 > 結單時顧客可自行修改數量
    customerCanEditCheckoutQuantity() {
      return this.$store.state.Prefer.p_store.customerCanEditCheckoutQuantity[0].selected
    },
    // 偏好 > 結單時可填寫發票資訊
    customerCheckoutCanSetReceiptInfo() {
      return this.$store.state.Prefer.p_store.customerCheckoutCanSetReceiptInfo[0].selected
    },
    // 偏好 > 代客直寄
    showDetail() {
      return this.$store.state.Prefer.p_store.showDetail[0].selected
    },
  },
  methods: {
    // 初始化
    initialization() {
      this.serverToken = this.$methods.getCookie('serverToken')
      this.storeId = this.$route.params.storeId
    },
    // 開啟 modal
    showModal(method, status, item) {
      if (method === 'freight') {
        // 運送方式
        if (status === 'add') {
          this.shippingTypeMethods = 'add'
          this.shippingTypeData = {
            name: '',
            adminEnable: false,
            customerEnable: false,
            cost: '',
            tipMessage: ''
          }
          this.shippingTypeModal.show()
        } else if (status === 'edit') {
          this.shippingTypeMethods = 'edit'
          this.shippingTypeData = {
            id: item.id,
            name: item.name,
            adminEnable: item.adminEnable,
            customerEnable: item.customerEnable,
            cost: item.cost,
            tipMessage: item.tipMessage
          }
          this.shippingTypeModal.show()
        }
      } else if (method === 'payfor') {
        // 付款方式
        if (status === 'add') {
          this.payforMethods = 'add'
          this.payforData = {
            name: '',
            adminEnable: false,
            customerEnable: false,
            note: ''
          }
          this.payforModal.show()
        } else if (status === 'edit') {
          this.payforMethods = 'edit'
          this.payforData = {
            id: item.id,
            name: item.name,
            adminEnable: item.adminEnable,
            customerEnable: item.customerEnable,
            note: item.note
          }
          this.payforModal.show()
        }
      } else if (method === 'autoAllocated') {
        this.autoAllocatedWarningModal.show()
      }
    },
    // 取得偏好
    getPrefer() {
      this.$store.dispatch('Prefer/getStoreInfo', {
        storeId: this.$route.params.storeId,
        serverToken: this.$methods.getCookie('serverToken')
      })
    },
    // 更新偏好
    updateConfiguration(item, data) {
      this.$store.dispatch('Prefer/updateConfiguration', {
        storeId: this.$route.params.storeId,
        serverToken: this.$methods.getCookie('serverToken'),
        category: 'p_store',
        item: item,
        data: data
      })
    },
    // 取得賣場資訊 (運送方式/付款方式)
    getStoreInfo(methodsArray) {
      this.$methods.switchLoading('show')
      const vm = this
      const getStoreInfoApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const getDataName = `{${methodsArray.toString()}}`
      const data = [
        {
          type: 5,
          ids: [this.storeId],
          methods: getDataName
        },
      ]
      // 是否要抓取運送方式
      if (methodsArray.includes('getShippingTypes')) {
        this.storeShippingTypes = {
          loading: true,
          data: []
        }
      }
      // 是否要抓取付款方式資料
      if (methodsArray.includes('getPaymentTypes')) {
        this.storePaymentTypes = {
          loading: true,
          data: []
        }
      }
      $.ajax({
        type: 'POST',
        async: true,
        url: getStoreInfoApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const storeInfo = res.data[0].objects[0]
            vm.storeInfo = storeInfo
            // 是否有抓取運送方式
            if (methodsArray.includes('getShippingTypes')) {
              vm.storeShippingTypes = {
                loading: false,
                data: storeInfo.shippingTypes
              }
            }
            // 是否有抓取付款方式資料
            if (methodsArray.includes('getPaymentTypes')) {
              vm.storePaymentTypes = {
                loading: false,
                data: storeInfo.paymentTypes
              }
            }
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
          this.dataTabelLoading = false
        },
      })
    },
    // 更新賣場資訊
    updateStoreInfo (attribute) {
      this.$methods.switchLoading('show')
      const vm = this
      const updateDescriptionApi = `${process.env.VUE_APP_API}/store/setAttribute`
      const header = {
        authorization: this.serverToken
      }
      const attributes = {}
      // 更新付款與出貨方式說明
      if (attribute === 'updateDescription') {
        attributes.setPaymentDescription = this.storeInfo.paymentDescription
      }
      // 更新出貨資訊
      if (attribute === 'shippingInfo') {
        attributes.setShipmentName = this.storeInfo.shipmentName
        attributes.setShipmentPhoneNumber = this.storeInfo.shipmentPhoneNumber
        attributes.setShipmentAddress = this.storeInfo.shipmentAddress
        attributes.setShipmentNote = this.storeInfo.shipmentNote
      }
      // 配單模式 (true = 系統自動配單,false = 賣家自行配單)
      if (attribute === 'autoAllocated') {
        attributes.setAutoAllocated = this.storeInfo.autoAllocated
      }
      // 開放顧客結單使用優惠 (true = 允許顯示促銷活動,false = 不允許(預設))
      if (attribute === 'allowCustomerDiscount') {
        attributes.setAllowCustomerDiscount = this.storeInfo.allowCustomerDiscount
      }
      // 個人賣場下單要求填寫收件地址 (true = 一定要填收件資訊才能下單, false = 不要求)
      if (attribute === 'receivingInfoNeeded') {
        attributes.setReceivingInfoNeeded = this.storeInfo.receivingInfoNeeded
      }
      // 如果選擇自動配單，系統將不允許顧客取消訂單
      if (attribute === 'allowCustomerCancelOrder') {
        attributes.setMerchOrderCancellationPeriod = null
      }
      const data= [{
        id: this.storeId,
        attributes: attributes
      }]
      $.ajax({
        type: 'PUT',
        async: true,
        url: updateDescriptionApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            if (attribute !== 'allowCustomerCancelOrder') vm.SweetAlert(res.code, res.message)
            if (attribute === 'autoAllocated' && vm.storeInfo.autoAllocated) {
              // 關閉自動配單提醒訊息
              vm.autoAllocatedWarningModal.hide()
              // 不允許顧客自動配單
              vm.updateStoreInfo('allowCustomerCancelOrder')
            }
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
          this.dataTabelLoading = false
        }
      })
    },
    // 新增/編輯運送方式
    updateShippingType() {
      const vm = this
      this.$methods.switchLoading('show')
      let updateShippinTypeApi = ''
      let ajaxType = ''
      const data = []
      // 新增
      if (this.shippingTypeMethods === 'add') {
        updateShippinTypeApi = `${process.env.VUE_APP_API}/shippingType/create`
        ajaxType = 'POST'
        data.push({
          storeId: this.storeId,
          name: this.shippingTypeData.name,
          adminEnable: this.shippingTypeData.adminEnable,
          customerEnable: this.shippingTypeData.customerEnable,
          cost: this.shippingTypeData.cost ? this.shippingTypeData.cost : 0,
          tipMessage: this.shippingTypeData.tipMessage
        })
      }
      //編輯
      else {
        updateShippinTypeApi = `${process.env.VUE_APP_API}/shippingType/update`
        ajaxType = 'PUT'
        data.push({
          id: this.shippingTypeData.id,
          name: this.shippingTypeData.name,
          adminEnable: this.shippingTypeData.adminEnable,
          customerEnable: this.shippingTypeData.customerEnable,
          cost: this.shippingTypeData.cost ? this.shippingTypeData.cost : 0,
          tipMessage: this.shippingTypeData.tipMessage
        })
      } 
      const header = {
        authorization: this.serverToken
      }
      $.ajax({
        type: ajaxType,
        async: true,
        url: updateShippinTypeApi,
        headers: header,
        data: JSON.stringify(data),
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.getStoreInfo('getShippingTypes')
            vm.shippingTypeModal.hide()
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        }
      })
    },
    // 新增/編輯付款方式
    updatePayfor() {
      const vm = this
      this.$methods.switchLoading('show')
      let updatePayforApi = ''
      let  ajaxType = ''
      const data = []
      // 新增
      if (this.payforMethods === 'add') {
        updatePayforApi = `${process.env.VUE_APP_API}/paymentType/create`
        ajaxType = 'POST'
        data.push({
          storeId: this.storeId,
          name: this.payforData.name,
          adminEnable: this.payforData.adminEnable,
          customerEnable: this.payforData.customerEnable,
          note: this.payforData.note
        }) 
      } 
      // 編輯
      else {
        updatePayforApi = `${process.env.VUE_APP_API}/paymentType/update`
        ajaxType = 'PUT'
        data.push({
          id: this.payforData.id,
          name: this.payforData.name,
          adminEnable: this.payforData.adminEnable,
          customerEnable: this.payforData.customerEnable,
          note: this.payforData.note
        }) 
      }
      const header = {
        authorization: this.serverToken
      }
      $.ajax({
        type: ajaxType,
        async: true,
        url: updatePayforApi,
        headers: header,
        data: JSON.stringify(data),
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.getStoreInfo('getPaymentTypes')
            vm.payforModal.hide()
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        }
      })
    },
  }
}
</script>